
/**
 * Enable ckeditor on the given selector
 *
 * @param element
 */

let editors = [];
window.enableCkeditor = function(element) {
    window.enableCkeditor4(element);
};

window.enableCkeditor4 = function(element) {
    let allEditors = document.querySelectorAll(element);
    let editorsLength = allEditors.length;

    // Convert element NodeList to an array
    let elementsArray = Array.prototype.slice.call(allEditors);

    // CKeditor 4
    if (Object.keys(CKEDITOR.instances).length > 0) {
        for(name in CKEDITOR.instances) {
            CKEDITOR.instances[name].destroy()
        }
    }

    var editor = {};
    for (let i = 0; i < editorsLength; ++i) {
        if (typeof CKEDITOR.instances[elementsArray[i].id] === 'undefined') {
            editor[elementsArray[i].id] = CKEDITOR.replace( elementsArray[i].id , CKEditorConfigs.defaultConfig);

            // The "change" event is fired whenever a change is made in the editor.
            editor[elementsArray[i].id].on( 'change', function( evt ) {
                document.getElementById(elementsArray[i].id).innerHTML = this.getData()
            });
        }
    }
};

/**
 *
 */
$.fn.itemSelectList = function() {

    this.each(function() {

        let $this = $(this);

        if ($this.data('is-active')) {
            console.log($this.data('is-active'));
            return false;
        }

        $this.data('is-active', true);

        let customFieldName = $this.data('custom-field-name');
        let elementNameSlug = $this.data('name-slug');
        let ajaxRoute = $this.data('ajax-route');

        // make selected pages sortable so the user can decide in which order the selected items should be saved
        // to the item that is worked on
        makeElementSortable('#' + elementNameSlug + '-selection');

        // turn the select list into a select2 instance
        // if the list consists of many item, this will make finding them easier
        $this.find('.select-list-select2').select2({
            closeOnSelect: false,
            theme: 'bootstrap4',
            templateResult: function (state) {
                if (!state.id) {
                    return state.text;
                }

                const parts = state.text.split('> ').length;
                const pageTitle = state.text.split('> ').pop();

                let breadCrumb = '';

                if (pageTitle !== state.text) {
                    breadCrumb = '<br /><small class="text-secondary">' + state.text + '</small>';
                }

                let $state = $(
                    '<span class="d-inline-block font-weight-bold" style="margin-left: '+ (parts * 10) +'px">' + pageTitle + breadCrumb + '</span>'
                );
                return $state;
            },
            templateSelection: function (state) {
                return state.text.split('> ').pop();
            }
        });

        // when making the selection, click the add button and add the selected pages via AJAX to the form
        $this.find('#add-' + elementNameSlug).on('click', function() {

            $.ajax({
                url: ajaxRoute,
                data: {
                    custom_field_name: customFieldName,
                    id: $('#' + elementNameSlug).val()
                },
                success: function(result) {
                    $this.find('#' + elementNameSlug + '-selection').append(result);

                    // empty the select dropdown
                    $this.find('#' + elementNameSlug).val(null).trigger("change");
                }
            });

        });
    });
};

/**
 * Language toggle
 *
 * Togglable fields must contain class "meta-field" and a class (e.g. .target-element) to which it will respond.
 * The toggle button should contain data-toggle="metaFields" and data-target=".target-element".
 */
$.fn.metaToggle = function(el) {

    $('.meta-field:not(.active)').hide();

    this.on('click', el, function(e) {

        e.preventDefault();

        var $this = $(this);

        var toggleClass = '.' + $this.data('toggle');

        $(toggleClass).hide();

        $this.closest('.nav-tabs').find('.nav-link').removeClass('active');
        $this.addClass('active');

        $(toggleClass + $this.data('target')).show();
    });

    $(document).on('click', '.meta-field .flag-icon', function() {

        var activeLanguageIndex = $(this).closest('.meta-field').attr('aria-labelledby');

        var nextLang = $(el).closest('.nav').find('li > a#' + activeLanguageIndex).parent().next().find('> a');

        if (nextLang.length === 0) {
            nextLang = $(el).closest('.nav').find('li:first a');
        }

        nextLang.trigger('click');
    });
};

/**
 * Adds the functionality for the address picker
 */
$.fn.addressPicker = function() {
    this.each(function() {

        let $this = $(this);

        // Get coordinates
        $this.find('.get-coordinates').on('click', function() {

            var current_lat = $this.find(':input[data-address="latitude"]').val();
            var current_lng = $this.find(':input[data-address="longitude"]').val();

            var address = {
                street: $this.find(':input[data-address="street"]').val(),
                street_number: $this.find(':input[data-address="street_number"]').val(),
                street_number_add: $this.find(':input[data-address="street_number_add"]').val(),
                postal_code: $this.find(':input[data-address="postal_code"]').val(),
                city: $this.find(':input[data-address="city"]').val(),
                country: $this.find(':input[data-address="country"]').val(),
            }

            var google_maps_popup_url = $this.data('google_maps_popup_url') +
                '?current_lat=' + current_lat +
                '&current_lng=' + current_lng +
                '&' + new URLSearchParams(address).toString();

            var dialog = bootbox.dialog({
                size: 'lg',
                title: $this.data('google_maps_popup_title'),
                message: '<p class="dialog-loading"><i class="fa fa-spin fa-spinner"></i> Loading...</p>',
                onHidden: function() {
                    // remove this dialog from the dom so we can reload its contents again later
                    $(this).remove();
                },
                buttons: {
                    confirm : {
                        label: 'Ok',
                        className: 'btn-success',
                        callback: function(e) {

                            $this.find(':input[data-address="latitude"]').val($(this).find('#map-latitude').val());
                            $this.find(':input[data-address="longitude"]').val($(this).find('#map-longitude').val());

                        }
                    }
                }
            });

            dialog.init(function(){
                dialog.find('.bootbox-body').load(google_maps_popup_url);
            });

        });
    });
}
