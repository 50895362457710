
window.$ = window.jQuery = window.Q = require('jquery');
require('jquery-ui-sortable-npm');

// This seems to break the bootstrap accordion functionality
// require('./bootstrap');

// CoreUI stuff
require('@popperjs/core');
require('perfect-scrollbar');
require('@coreui/coreui');

// DataTables for all the overview pages
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');

// Better form select
require('select2');

// Plugin to show messages
toastr = require('toastr');
toastr.options.closeButton = true;
toastr.options.showMethod = 'slideDown';
toastr.options.progressBar = true;

// Bootstrap Modal plugins
eModal = require("./plugins/eModal");
bootbox = require('bootbox/bootbox');

// Input masks
mask = require('jquery-mask-plugin/dist/jquery.mask.min');
moment = require('moment');
moment.locale = require('moment/locale/nl');

// CKEditor 4 - richtext editor
window.CKEDITOR_BASEPATH = '//cdn.ckeditor.com/4.15.1/full-all/';
require('ckeditor4');
CKEditorConfigs = require('./ckeditor4.configs');

// Custom Toolbox Stuff
require('./plugins/custom-plugins');

$(document).ready(function() {

    // add select2
    $('.select2').select2({
        theme: 'bootstrap4',
    });

    $('.select2-combinations').select2({
        theme: 'bootstrap4',
        width: '100%',
        templateSelection: function(selection) {
            return $(selection.element).parent().attr('label') + ': ' + selection.text;
        }
    });

    $(document.body).tooltip({
        html: true,
        selector: '[data-toggle="tooltip"]',
        delay: { "show": 100, "hide": 150 },
        title: function() {

            var that = this;

            // to prevent multiple ajax calls, we will use a cache
            if (that.tooltipTextCache) return that.tooltipTextCache;

            if (typeof $(this).data('tooltip') !== 'undefined') {

                $.ajax({
                    url: '/admin/tooltip/' + encodeURI($(this).data('tooltip')),
                    type: 'get',
                    async: false,
                    success: function(response){
                        that.tooltipTextCache = response;
                    }
                });
            }

            return that.tooltipTextCache;
        }
    });

    // show selected file name in custom file upload
    $(document).on('change', '.custom-file-input', function(){
        $(this).next('.custom-file-label').addClass("selected").html(this.files[0].name);
    });

    /**
     * Language toggle
     *
     * Togglable fields must contain class "meta-field" and a class (e.g. .target-element) to which it will respond.
     * The toggle button should contain data-toggle="metaFields" and data-target=".target-element".
     */
    $(document).metaToggle('a[data-toggle="meta-field"]');

    // trigger the ckeditors
    window.enableCkeditor('.ckeditor');
});

/**
 * When the users leaves the page (or reloads), empty any editors still left.
 */
window.onbeforeunload = function() {
    // CKeditor 4
    if (Object.keys(CKEDITOR.instances).length > 0) {
        for(name in CKEDITOR.instances) {
            CKEDITOR.instances[name].destroy()
        }
    }
};

/**
 * Helper function to make elements sortable
 */
function makeElementSortable(selector) {
    $(selector).sortable({
        containment: selector,
        opacity: 0.5,
        handle: ".sortable-handle",
        axis: "y",
        tolerance: "pointer",
        create:function(){
            // this will solve an issue with page jumps when the page becomes shorter when you start dragging.
            var list=this;
            resize=function(){
                jQuery(list).css("min-height","0");
                jQuery(list).height(jQuery(list).height());
            };
            jQuery(list).css('min-height', jQuery(list).height());
        },
        update: function () {

            // if the data attribute "sort-update-url" is set, then we want to update the list after sorting
            // using an ajax request
            if (typeof $(selector).data('sort-update-url') !== 'undefined') {
                $.ajax({
                    url: $(selector).data('sort-update-url'),
                    method: 'POST',
                    data: {
                        _token: csrf_token,
                        items: $(this).sortable('toArray', {attribute:'data-id'})
                    },
                    success: function(result) {
                        toastr.success(result.msg);
                    }
                });

                return;
            }

            // Update the position value so items will be give the correct order when saving
            $(selector).find('> div').each(function() {
                $(this).find('input[name*="[position]"]').val($(this).index())
            });
        }
    }).disableSelection();
}
window.makeElementSortable = makeElementSortable;
