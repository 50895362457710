
// The NPM package does not seem to contain there plugins so we add them manually
CKEDITOR.plugins.addExternal( 'a11ychecker', '/assets/ckeditor_plugins/a11ychecker/plugin.js' );
CKEDITOR.plugins.addExternal( 'customImage', '/assets/ckeditor_plugins/customImage/plugin.js' );
CKEDITOR.plugins.addExternal( 'customLink', '/assets/ckeditor_plugins/customLink/plugin.js' );
CKEDITOR.plugins.addExternal( 'font', '/assets/ckeditor_plugins/font/plugin.js' );
CKEDITOR.plugins.addExternal( 'justify', '/assets/ckeditor_plugins/justify/plugin.js' );
// CKEDITOR.plugins.addExternal( 'dialogadvtab', '/assets/ckeditor4/plugins/dialogadvtab/plugin.js' );

export const defaultConfig = {
    toolbar: [
        { name: 'styles', items: [ 'Format' /*, 'Font', 'FontSize' */ ] },
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike' /* , 'Subscript', 'Superscript', '-', 'CopyFormatting' */, 'RemoveFormat' ] },
        { name: 'clipboard', items: [ /*'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' */ ] },
        //'/',
        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent' /* , '-', 'Blockquote' */, '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
        { name: 'links', items: [ 'Link', 'Unlink' /* , 'Anchor' */ ] },
        { name: 'insert', items: [ 'CustomImage', 'Table', 'HorizontalRule', 'SpecialChar', 'Iframe' ] },
        //'/',
        { name: 'editing', items: [ 'Scayt', 'A11ychecker', 'Source'] }
    ],
    height: '20em',
    removePlugins: 'image,link',
    extraPlugins: 'customImage,customLink,a11ychecker,font,justify', // dialogadvtab
    filebrowserBrowseUrl: '/elfinder/ckeditor',
    filebrowserImageBrowseUrl: '/elfinder/ckeditor',
    linkDefaultProtocol: 'https://',
    format_tags: 'p;h1;h2;h3;h4;h5;h6',
    toolboxLink: {
        moduleListUrl: '/toolbox-link/modules-list',
        itemsListUrl: '/toolbox-link/items-list',
    },
    a11ychecker_quailParams: {
        jsonPath: '/assets/ckeditor_plugins/a11ychecker/libs/quail',
        // Override Accessibility Checker guidelines from the default configuration.
        guideline: [
            'imgNonDecorativeHasAlt',
            'imgImportantNoSpacerAlt',
            'aTitleDescribesDestination',
            'aAdjacentWithSameResourceShouldBeCombined',
            'aImgAltNotRepetitive',
            'aMustNotHaveJavascriptHref',
            'aSuspiciousLinkText',
            'blockquoteNotUsedForIndentation',
            'documentVisualListsAreMarkedUp',
            'headerH1',
            'headerH2',
            'headerH3',
            'headerH4',
            'imgAltIsDifferent',
            'imgAltIsTooLong',
            'imgAltNotEmptyInAnchor',
            'imgAltTextNotRedundant',
            'imgHasAlt',
            'imgShouldNotHaveTitle',
            'linkHasAUniqueContext',
            'pNotUsedAsHeader',
            'tableDataShouldHaveTh',
            'imgWithEmptyAlt'
        ]
    },
    contentsCss: [
        'https://cdn.ckeditor.com/4.15.1/full-all/contents.css',
        'https://ckeditor.com/docs/ckeditor4/4.15.1/examples/assets/accessibilitychecker/contents.css'
    ],

}
